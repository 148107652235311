/** @jsx jsx */
// import React from "react"
import { graphql, Link } from "gatsby"
import { jsx } from "theme-ui"
import { TheLayout, TheDebugBox, Theseo } from "../components"
import Items from "../components/Items"

const SecondPage = ({ data }) => {
  const thearticles = data.allArticleData.edges
  return (
    <TheLayout>
      <Theseo title="Page two" />
      <div>
        <h1>Hi from the second page</h1>
        <p>Welcome to page 2</p>
        <Items items={thearticles} />
        <TheDebugBox object={thearticles} />
        <Link to="/" sx={{ variant: "links.navlink" }}>
          Go back to the homepage
        </Link>
      </div>
    </TheLayout>
  )
}

export default SecondPage

export const Newerquery = graphql`
  query {
    allNameData {
      totalCount
      edges {
        node {
          id
          name {
            firstName
            lastName
          }
        }
      }
    }
    allArticleData {
      totalCount
      edges {
        node {
          lorem {
            words
          }
          name {
            firstName
            lastName
          }
        }
      }
    }
  }
`

// <Items items={thearticles} />
